import React from 'react';
import Section from '../../../HOC/Section';

const mapMarker = () => {
  return (
    <Section id='map'>
      <div>
        <iframe
          title='mapMarker'
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3019.028954431134!2d29.37948701540854!3d40.82732767931952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9d1b5f83e9a0e97c!2zNDDCsDQ5JzM4LjQiTiAyOcKwMjInNTQuMCJF!5e0!3m2!1str!2str!4v1665863553228!5m2!1str!2str'
          width='100%'
          height='450'
          frameBorder='0'
          style={{ border: 0, marginBottom: '-7px' }}
          allowFullScreen
        />
      </div>
    </Section>
  );
};

export default mapMarker;
