import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1
  };
const CarouselMobile = () => {
  return (
    <div className="logos">
    <Slider {...settings}>
          <div>
            <img src="https://www.yenicevre.com.tr/wp-content/uploads/2022/02/ref1.png" alt=""/>
          </div>
          <div>
            <img src="https://www.yenicevre.com.tr/wp-content/uploads/2022/02/ref2.png" alt=""/>
          </div>
          <div>
            <img src="https://www.yenicevre.com.tr/wp-content/uploads/2022/02/hcp.png" alt=""/>
          </div>
          <div>
            <img src="https://www.yenicevre.com.tr/wp-content/uploads/2022/02/ldl.png" alt=""/>
          </div>
          <div>
            <img src="https://www.yenicevre.com.tr/wp-content/uploads/2022/02/ref4.png" alt=""/>
          </div>
        </Slider>
        </div>
    );

};


export default CarouselMobile;