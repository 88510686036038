import React from 'react';
import { useState, useEffect } from 'react';
import Section from '../../../HOC/Section';
import Carousel from '../../../HOC/Carousel';
import CarouselMobile from '../../../HOC/CarouselMobile';


const Service = () => {
  const [isMobile, setIsMobile] = useState(false)
 
  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 1024) {
        setIsMobile(true)
        isMobile = true;
    } else {
        setIsMobile(false)
        isMobile = false;
    }
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })
  return (
    <Section id='services'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            Ürünlerimiz
          </h3>
          <h6 className='section-subtitle mr-auto ml-auto'>
          </h6>
        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-md-6 col-lg-4 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                <i class="fa-solid fa-pump"></i>
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Pompa Sistemleri</h5>
                  <p className='service-description'>
                    
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-4 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i class="fa-solid fa-industry-windows"></i>
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Arıtma Tesisleri</h5>
                  <p className='service-description'>
                    {/*Endüstri, paket, beton, şehir şebeke suları arıtma tesisleri.*/}
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-4 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className='fas fa-fist-raised' />
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Taahhüt İşleri</h5>
                  <p className='service-description'>
                    
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-4 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                <i class="fa-solid fa-arrow-up-from-water-pump"></i>
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Terfi İstasyonları</h5>
                  <p className='service-description'>
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-4 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                <i class="fa-solid fa-building"></i>
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Bina Drenajları</h5>
                  <p className='service-description'>
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-4 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                <i class="fa-solid fa-ship"></i>
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Gemi Sintile Suyu</h5>
                  <p className='service-description'>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isMobile && <CarouselMobile/>}
        {!isMobile && <Carousel/>}
      </div>
    </Section>
  );
};

export default Service;
