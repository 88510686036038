import React from 'react';

import Section from '../../../HOC/Section';
import aboutImage from '../../../assets/img/about.jpg';

const about = () => {
  return (
    <Section id='about'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>Demka </span>Mühendislik
          </h3>
          <h6 className='section-subtitle mr-auto ml-auto'>
          Çevresel ve etik değerlere saygılı, sürdürülebilir ve yenilikçi teknolojileri takip ederek iş faaliyetlerimize devam ediyoruz.
          </h6>
        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-md-12 col-lg-6 mb-3'>
              <div className='aboutImage'>
                <img src={aboutImage} alt='about company' />
              </div>
            </div>
            <div className='col-md-12 col-lg-6'>
              <h3 className='about-title'>Hakkımızda</h3>
              <div className='about-description'>
                <p style={{fontSize: 18}}>
                Demka Mühendislik 16 yıllık tecrübeyle arıtım sektörüne ve bu alanda hizmet veren firmalara daha iyi hizmet vermek amacıyla 2015 yılında kurulmuştur. Demka Mühendislik; su ve atıksu arıtma, satış, kurulum ve danışmanlık hizmetleri vermek üzere kurulmuş bir şirkettir. Arıtma ekipmanları, komple pompa istasyonları, betonarme pompa istasyonu ekipmanları, dalgıç atık su pompaları, akış jeneratörleri, mikserler, dozaj pompaları ve aksesuarları için gerekli hizmetleri size sağlayabilir.


                </p>
                {/*<button className='btn btn-primary rounded-0'>Read More</button>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default about;
