import React from 'react';

import Link from '../Link/Link';

const footer = () => {
  return (
    <footer className=''>
      <div className='container text-light pt-5'>
        <div className='row'>
          <div className='col-sm-6 col-md-6 col-lg-4 mb-5'>
            <div className='footer-title'>
              <h6>Hakkımızda</h6>
            </div>
            <div className='footer-content'>
              <p>
                <small className='text-muted'>
                Demka Mühendislik 16 yıllık tecrübeyle arıtım sektörüne ve bu alanda hizmet veren firmalara daha iyi hizmet vermek amacıyla 2015 yılında kurulmuştur. Demka Mühendislik; su ve atıksu arıtma, satış, kurulum ve danışmanlık hizmetleri vermek üzere kurulmuş bir şirkettir. Arıtma ekipmanları, komple pompa istasyonları, betonarme pompa istasyonu ekipmanları, dalgıç atık su pompaları, akış jeneratörleri, mikserler, dozaj pompaları ve aksesuarları için gerekli hizmetleri size sağlayabilir.
                </small>
              </p>
              
            </div>
          </div>
          <div className='col-sm-6 col-md-6 col-lg-2 mb-5'>
            <div className='footer-title'>
              <h6>Hızlı Linkler</h6>
            </div>
            <div className='footer-content'>
              <ul className='list-group quick-links'>
                <li>
                  <Link target='home' offset={-120}>
                    Anasayfa
                  </Link>
                </li>
                <li>
                  <Link target='about'>Hakkımızda</Link>
                </li>
                <li>
                  <Link target='services'>Ürünler</Link>
                </li>
                <li>
                  <Link target='blog'>Blog</Link>
                </li>
                <li>
                  <Link target='contact'>İletişim</Link>
                </li>
              </ul>
            </div>
          </div>
          {/*<div className='col-sm-6 col-md-6 col-lg-3 mb-5'>
            <div className='footer-title'>
              <h6>Latest News</h6>
            </div>
            <div className='footer-content'>
              <p>
                <small className='text-muted'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </small>
              </p>
              <p>
                <small className='text-muted'>
                  Pellentesque et pulvinar enim. Quisque at tempor ligula Natus
                  error sit voluptatem
                </small>
              </p>
              <p>
                <small className='text-muted'>accusantium doloremque</small>
              </p>
            </div>
  </div>*/}
          <div className='col-sm-6 col-md-6 col-lg-3 mb-5'>
            <div className='footer-title'>
              <h6>İletişim</h6>
            </div>
            <div className='footer-content'>
              <p className='text-muted'>
                <small>Adres : Özgürlük Mh. 212. Sk. No 1/1 Çayırova/KOCAELİ</small>
              </p>
              <p className='text-muted'>
                <small>Telefon : +90 (532) 788 52 07</small>
              </p>
              <p className='text-muted'>
                <small>E-mail : info@demkadanismanlik.com</small>
              </p>
              {/*<div className='social-media mt-4'>
                <a href='!#' className='text-light'>
                  <i className='fab fa-facebook-f mr-4' />
                </a>
                <a href='!#' className='text-light'>
                  <i className='fab fa-twitter mr-4' />
                </a>
                <a href='!#' className='text-light'>
                  <i className='fab fa-instagram mr-4' />
                </a>
              </div>*/}
            </div>
          </div>
        </div>
      </div>
      <div className='bottom-footer pt-3 pb-3 text-center'>
        <small>© Tüm Hakları Saklıdır. Tasarım: Dogukan GOKOVA</small>
      </div>
    </footer>
  );
};

export default footer;
