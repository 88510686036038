import React from 'react';

import Section from '../../../HOC/Section';

import blogImage1 from '../../../assets/img/blog1.png';
import blogImage2 from '../../../assets/img/blog2.jpeg';
import blogImage3 from '../../../assets/img/blog3.jpg';

const Blog = () => {
  return (
    <Section id='blog'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>Blog</span>
          </h3>
        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={blogImage1} className='card-img-top' alt='Blog 1' />
                <div className='card-body'>
                  <h5 className='card-title'>Prefabrik Pompa İstasyonları</h5>
                  <p className='card-text'>
                  Integra Modeli Pompa İstasyonu Kuru montajlı tip
pompalarla çalışan atık su transfer istasyonlarıdır. Patentli sistem, kuru ve ıslak kuyu odalarını tek ünitede birleştirir...
                  </p>
                 {/*<a href='#!' className='btn btn-sm btn-primary'>
                    Read More
  </a>*/}
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={blogImage2} className='card-img-top' alt='Blog 2' />
                <div className='card-body'>
                  <h5 className='card-title'>Atıksu Uygulamalarında IEC Motor Verimliliği Standartları</h5>
                  <p className='card-text'>
                    Dünyadaki elektrik enerjisi tüketiminin en az yüzde 10’undan pompalar sorumludur ve kullanımdaki tüm pompaların üçte ikisi, yüzde 60’a kadar elektriği israf etmektedir. Tüm işletmelerin yüksek verimli pompa sistemleri kullanmaya başlaması halinde dünya elektrik tüketimi yüzde 4 oranında azalabilir...
                  </p>
                   {/*<a href='#!' className='btn btn-sm btn-primary'>
                    Read More
  </a>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Blog;
