import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import Banner1 from '../assets/img/blog1.jpg'
import Banner2 from '../assets/img/blog2.jpg'
import Banner3 from '../assets/img/blog3.jpg'

const AutoplaySlider = withAutoplay(AwesomeSlider);


const Slider = () => (
    <AutoplaySlider
    play={true}
    cancelOnInteraction={false} // should stop playing on user interaction
    interval={6000}
    bullets={false}
  >
    <div data-src={Banner1} />
    <div data-src={Banner2} />
    <div data-src={Banner3} />
  </AutoplaySlider>
  );

export default Slider;